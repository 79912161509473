.file-upload-input {
    display: none;
  }
  
  .file-upload-label {
    display: inline-block;
    cursor: pointer;
    border: 1px solid #ccc;
    padding: 4px 8px;
  }
  
  .file-upload-label span {
    font-size: 14px;
  }
  
  .file-upload-label:hover {
    background-color: #f0f0f0;
  }
  