@tailwind base;
@tailwind components;
@tailwind utilities;
@import '~leaflet/dist/leaflet.css';


.hide-scrollbar{
  -ms-overflow-style: none; /* for Internet Explorer, Edge */
  scrollbar-width: none; /* for Firefox */
  &::-webkit-scrollbar {
    display: none; /* for Chrome, Safari, Opera */
  }
}

.draggable {
  display: flex;
  align-items: center;
  gap: 1rem;
  margin: 1rem;
}

.draggable-category {
  display: flex;
  align-items: flex-start;
  gap: 1rem;
  margin: 1rem;
}

h2 {
  margin: 0;
}

.drag-handle {
  margin-top: inherit;
  border: 1px solid rgb(209, 209, 209);
  background: rgb(209, 209, 209);
  padding: 0.1rem;
  border-radius: 4px;
}

.category-container {
  width: 90%;
}

.item {
  border: 2px solid gray;
  border-radius: 4px;
  padding: 1rem;
  width: 100%;
  background: lightgray;
}

.leaflet-container {
  height: 400px;
  width: 400px;
  border: 1px solid #ccc;
}

.address-dialog .leaflet-container {
  margin: 5px 20px;
  height: 300px;
  width: 300px;
  /* border: 1px solid #ccc; */
}

/* 
.leaflet-pane{
  width: 200px;
} */

.address-dialog .leaflet-map-pane{
  width: 100% !important;
}
/* 


.address-dialog .leaflet-div-icon {
  background: transparent;
  border: none;
} */
/* 
.address-dialog .leaflet-pane{
  width: 200px;
} */